import axios from 'axios';
let form;

function success() {
  form.reset();
  form.classList.remove('form-submitting');
  form.classList.add('form-success');
  window.setTimeout(() => {
    form.classList.remove('form-success');
  }, 5000);
}

function error(errors) {
  const els = Array.from(form.querySelectorAll('.js-form-error'));
  els.forEach(item => item.remove());
  form.classList.remove('form-submitting');
  form.classList.add('form-error');

  Object.keys(errors).forEach(item => {
    const input = form.querySelector(`*[name="${item}"]`);
    const el = document.createElement('p');
    el.innerHTML = errors[item];
    el.classList.add('form__error', 'js-form-error');
    input.insertAdjacentElement('afterend', el);
  });
}

function handleSubmit(e) {
  e.preventDefault();
  form.classList.add('form-submitting');
  form.classList.remove('form-success', 'form-error');
  const data = new FormData(form);

  axios.post('/', data)
  .then(function(res) {
    if ('errors' in res.data) {
      error(res.data.errors);
    } else {
      success();
    }
  })
  .catch(function (error) {
    console.log(error);
    form.classList.remove('form-submitting');
  });
}

export default function() {
  form = document.querySelector('#contact-form');
  if (!form) {
    return;
  }

  form.addEventListener('submit', handleSubmit);
}

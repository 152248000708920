import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// childSelector is to handle elements like paragraphs within Craft's rich text blocks that we can't target directly
const elSelector = '.js-anim-el';
const parentSelector = '.js-anim-parent > *';
const fadeSelector = '.js-anim-fade';
const batchSelector = '.js-anim-batch';
const batchParentSelector = '.js-anim-batch-parent > * ';

const fadeArray = Array.from(document.querySelectorAll(fadeSelector));

const elArray = [
  Array.from(document.querySelectorAll(elSelector)),
  Array.from(document.querySelectorAll(parentSelector)),
].flat();

const batchArray = [
  Array.from(document.querySelectorAll(batchSelector)),
  Array.from(document.querySelectorAll(batchParentSelector)),
].flat();

function anim(arg) {
  const stagger = Array.isArray(arg) ? 0.1 : 0;
  gsap.fromTo(arg, {
    y: 10,
    autoAlpha: 0,
  }, {
    y: 0,
    autoAlpha: 1,
    duration: 0.6,
    stagger: stagger,
    ease: "power1.out",
  });
}

export default function() {
  if (batchArray.length) {
    ScrollTrigger.batch(batchArray, {
      once: true,
      onEnter: anim,
    });
  }

  if (elArray.length) {
    elArray.forEach(item => {
      ScrollTrigger.create({
        trigger: item,
        once: true,
        onEnter: () => { anim(item) },
      })
    });
  }

  if (fadeArray.length) {
    fadeArray.forEach(item => {
      gsap.fromTo(item, {
        autoAlpha: 0,
      }, {
        scrollTrigger: {
          trigger: item,
          once: true,
        },
        autoAlpha: 1,
        duration: 0.6,
        ease: "power1.in",
      });
    });
  }

  const tiltEls = Array.from(document.querySelectorAll('.js-tilt'));
  if (tiltEls.length) {
    gsap.set(tiltEls, {
      z: 100,
      transformPerspective: 1000,
    });

    function handleMouse(e) {
      // Setup a timer
      let timeout;

      // Listen for resize events
      document.addEventListener('mousemove', function(e) {
      	// If there's a timer, cancel it
      	if (timeout) {
      		window.cancelAnimationFrame(timeout);
      	}

        // Setup the new requestAnimationFrame()
      	timeout = window.requestAnimationFrame(function() {
          // Run our resize functions
          const xPos = (e.clientX/window.innerWidth);
          const yPos = (e.clientY/window.innerWidth);

          gsap.to(tiltEls, {
            rotationX: -yPos,
            rotationY: -xPos,
            duration: 1,
            ease: "power1.out",
          });
      	});
      }, false);
    }

    handleMouse();
  }
}

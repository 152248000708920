import gsap from 'gsap';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
let map;
let geoJSONItems = {
  type: "FeatureCollection",
  features: []
}

const closeBtns = Array.from(document.querySelectorAll('.js-close-map-modal'));

function showModal(e) {
  const selector = `#modal-${e.currentTarget.dataset.modal}`;
  const modal = document.querySelector(selector);
  const box = modal.querySelector('.js-map-modal-box');
  const tl = gsap.timeline();
  tl
  .set(modal, {left: 0})
  .add('start')
  .fromTo(modal, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.1,
  }, 'start')
  .fromTo(box, {
    opacity: 0,
    y: 20,
  }, {
    opacity: 1,
    y: 0,
    duration: 0.1,
    ease: 'back.out(1.7)',
  }, 'start');
}

function hideModal(e) {
  const modal = e.target.closest('.js-map-modal');
  const tl = gsap.timeline();
  tl
  .to(modal, {
    opacity: 0,
    duration: 0.1,
    ease: 'none',
  })
  .set(modal, {left: '-100%'});
}

function filterMarkers() {
  const checked = Array.from(document.querySelectorAll('.js-marker-toggle:checked'));
  const filters = checked.map(item => parseInt(item.value));
  const filterData = geoJSONItems.features.filter(item => filters.includes(item.properties.markerType));
  // Create new geoJSONItems object so we don't override original geoJSONItems
  const geoJSONItemsFilter = {
    type: "FeatureCollection",
    features: filterData
  }

  // setData will automatically trigger updateMarkers
  map.getSource('locations').setData(geoJSONItemsFilter);
}

function itemToGeoJSONPoint(item) {
  const obj = {
    id: item.id,
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [parseFloat(item.lng), parseFloat(item.lat)]
    },
    properties: {
      markerType: item.markerType,
      markerColor: item.markerColor
    }
  }

  geoJSONItems.features.push(obj)
}

function zoomCluster(e) {
  const id = parseInt(e.currentTarget.dataset.id);
  const features = map.querySourceFeatures('locations');
  const match = features.find(item => item.properties.cluster_id === id);
  map.getSource('locations').getClusterExpansionZoom(id, function(err, zoom) {
    if (err) return;
    map.easeTo({
      center: match.geometry.coordinates,
      zoom: zoom + 2
    });
  });
}

// objects for caching and keeping track of HTML marker objects (for performance)
let markers = {};
let markersOnScreen = {};

function updateMarkers() {
  let newMarkers = {};
  const features = map.querySourceFeatures('locations');

  // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
  // and add it to the map if it's not there already
  features.forEach((item, i) => {
    const coords = item.geometry.coordinates;
    const props = item.properties;
    const id = props.cluster_id ? props.cluster_id : item.id;

    let marker = markers[id];

    if (!marker) {
      const el = document.createElement('div');
      if (props.cluster) {
        // this property is only present when the feature is clustered
        // generate your clustered icon using props.point_count
        el.className = `cluster`;
        el.dataset.coords = coords.toString();
        el.dataset.id = id;
        el.addEventListener('click', zoomCluster);
      } else {
        // feature is not clustered, create an icon for it
        el.className = `marker marker--${props.markerType}`;
        el.dataset.modal = id;
        el.dataset.markerType = props.markerType;
        el.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 19"><path fill="${props.markerColor}" fill-rule="evenodd" d="M6.184 8.457C5 8.457 4.037 7.456 4.037 6.221c0-1.235.962-2.236 2.147-2.236 1.186 0 2.147 1.001 2.147 2.236 0 1.235-.961 2.236-2.147 2.236m0-7.79c-3.037 0-5.5 2.564-5.5 5.728 0 3.163 5.5 12.272 5.5 12.272s5.5-9.109 5.5-12.272c0-3.164-2.462-5.728-5.5-5.728"/></svg>`;
        el.addEventListener('click', showModal);
      }

      markers[id] = new mapboxgl.Marker({element: el}).setLngLat(coords);
      marker = markers[id];
    }

    newMarkers[id] = marker;

    if (!markersOnScreen[id]) {
      marker.addTo(map);
    }
  })

  // for every marker we've added previously, remove those that are no longer visible
  Object.keys(markersOnScreen).forEach(id => {
    if (!newMarkers[id]) {
      markersOnScreen[id].remove();
    }
  });

  markersOnScreen = newMarkers;
}

function getMarkers() {
  axios.get('/locations.json')
  .then(function(response) {
    const items = response.data.data.filter(item => item.show);
    items.forEach(itemToGeoJSONPoint);

    map.addSource('locations', {
      type: 'geojson',
      data: geoJSONItems,
      cluster: true,
      clusterRadius: 30,
    });

    map.addLayer({
      id: 'clustered-point',
      type: 'circle',
      source: 'locations',
      filter: ['has', 'point_count'],
      paint: {
        'circle-radius': 0,
        'circle-stroke-width': 0,
      }
    });

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'locations',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 0,
        'circle-stroke-width': 0,
      }
    });

    map.on('render', function () {
      if (!map.isSourceLoaded('locations')) {
        return;
      }

      updateMarkers();
    });
  })

  .catch(function(error) {
    // handle error
    console.log(error);
  })
  .then(function() {
    // always executed
  });
}

export default function() {
  const mapEl = document.querySelector('#map');

  if (!mapEl) {
    return;
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoibmljZWFuZHNlcmlvdXMiLCJhIjoiY2pnMmQ0ZDJsMjNnMTJ3bGx6N2tia2pkMSJ9.t2ZZo5rozxC3gdX4cT0dNg';
  map = new mapboxgl.Map({
    container: 'map',
    center: [4.5, 40], // starting position [lng, lat]
    zoom: 1, // starting zoom
    scrollZoom: false,
    style: 'mapbox://styles/niceandserious/ckmaw6rvs87a417pgfq7m6783'
  });

  map.addControl(new mapboxgl.NavigationControl());

  map.on('load', function() {
    getMarkers();

    const filters = Array.from(document.querySelectorAll('.js-marker-toggle'))
    filters.forEach(marker => {
      marker.addEventListener('change', filterMarkers)
    })

    closeBtns.forEach(item => {
      item.addEventListener('click', hideModal);
    });
  })
}

import Player from '@vimeo/player';

export default function() {
  const videos = Array.from(document.querySelectorAll('.js-video'))

  if (videos.length === 0) {
    return
  }

  videos.forEach((video, i) => {
    const videoContainer = video.querySelector('.js-video-container')
    const url = videoContainer.dataset.url

    if (!url) {
      return
    }

    const player = new Player(videoContainer, {
      url: url,
      byline: false,
      controls: true,
      responsive: true,
      title: false,
      muted: true,
    });
  });
}

import Player from '@vimeo/player';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const scaleFactor = window.innerWidth > 1280 ? 2.2 : 1.8;
const body = document.body;
const numSections = 6;
let currentIndex = 0;
let start = true;

let svgMain;
let svgNodes;
let homeHeading;
let homeHeadingParent;
let starHeadingParents;
let starHeadings;
let lines;
let coral;
let hexagon;
let homePromptsParent;
let homePrompts;
let storyBtns;
let closeBtn;
let circleItems;
let imageItems;
let blockItems;
let links;
let resetLinks;
let svgLinks;
let slideTl;
let beginBtn;
let prevBtn;
let nextBtn;
let stepBtns;
let header;
let footer;
let player;
let posVals;

if (window.innerWidth > 1280) {
  posVals = [
    {x: 330, y: 350},
    {x: -900, y: 350},
    {x: 550, y: -150},
    {x: -1150, y: -150},
    {x: 330, y: -620},
    {x: -900, y: -620},
  ];
} else {
  posVals = [
    {x: 300, y: 100},
    {x: -700, y: 100},
    {x: 500, y: -300},
    {x: -900, y: -300},
    {x: 300, y: -700},
    {x: -700, y: -700},
  ];
}


function initSlideShow() {
  const slides = Array.from(document.querySelectorAll('.js-home-slide'));
  const duration = 1;
  const delay = 4;
  slideTl = gsap.timeline({onComplete: repeat});
  slides.forEach(function(el, index){
    const offset = index === 0 ? 0 : "-=" + duration; // insert first animation at a time of 0 or all other animations at a time that will overlap with the previous animation fading out.
    slideTl.to(el, {
      duration: duration,
      autoAlpha: 1,
      repeat: 1,
      yoyo: true,
      repeatDelay: delay,
      ease: 'none',
    }, offset);
    // when the last image fades out we need to cross-fade the first image
    if (index === slides.length - 1){
      slideTl.to(slides[0], {
        duration: duration,
        autoAlpha: 1,
        ease: 'none',
      }, offset);
    }
  });

  function repeat() {
    slideTl.play(duration);
  }
}

function initVideo() {
  const video = document.querySelector('.js-home-video');
  const videoContainer = video.querySelector('.js-video-container')
  const url = videoContainer.dataset.url

  if (!url) {
    return;
  }

  player = new Player(videoContainer, {
    url: url,
    byline: false,
    controls: true,
    responsive: true,
    title: false,
  });

  player.on('loaded', function() {
    storyBtns.forEach(btn => btn.addEventListener('click', showVideo));
    closeBtn.addEventListener('click', hideVideo);
  })
}

function showVideo(e) {
  e.preventDefault();
  slideTl.pause();
  const videoModal = document.querySelector('#home-video');
  const video = videoModal.querySelector('.js-video');
  const tl = gsap.timeline();
  tl
  .set(videoModal, {left: 0})
  .set(body, {overflow: 'hidden'})
  .fromTo(videoModal, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.1,
  })
  .from(video, {
    opacity: 0,
    y: 10,
    duration: 0.1,
    onComplete: () => {
      player.setCurrentTime(0);
      player.play();
    }
  });
}

function hideVideo(e) {
  e.preventDefault();
  slideTl.play();
  player.pause();
  player.setCurrentTime(0);
  const videoModal = document.querySelector('#home-video');
  const tl = gsap.timeline();
  tl
  .to(videoModal, {
    opacity: 0,
    duration: 0.1,
  })
  .set(videoModal, {left: 'calc(-100% - 100vw)'})
  .set(body, {overflow: 'auto'})
}

function backToStart(e) {
  if (e) {
    e.preventDefault();
    currentIndex = e.currentTarget.dataset.index;
  }

  prevBtn.dataset.index = 0;
  nextBtn.dataset.index = 1;

  slideTl.play();

  const tl = gsap.timeline();
  tl.add('start');
  tl
  .to(svgMain, {
    scale: 1,
    x: 0,
    y: 0,
    duration: 0.5,
  }, 'start')
  .add(circleItems[currentIndex].outTl.restart(), 'start')
  .add(blockItems[currentIndex].outTl.restart(), 'start')
  .add(imageItems[currentIndex].outTl.restart(), 'start')
  .to(starHeadings, {
    opacity: 1,
    duration: 0.5,
    pointerEvents: 'auto',
  }, 'start')
  .to(homePrompts, {
    opacity: 1,
    y: 0,
    stagger: 0.1,
    duration: 0.25,
    pointerEvents: 'auto',
  }, 'start')
  .to(homePromptsParent, {
    zIndex: 'auto',
    duration: 0.25,
  }, 'start')
  .to(homeHeading, {
    opacity: 1,
    y: 0,
    duration: 0.5,
  }, 'start')
  .to([prevBtn, nextBtn], {
    opacity: 0,
    duration: 0.25,
    stagger: 0.1,
  }, 'start')
  .to(header, {
    opacity: 1,
    y: 0,
    duration: 0.25,
  }, 'start')
  .call(() => {
    // Use classes where setting things with gsap might interfere with mobile
    body.classList.remove('desktop-overview-is-active')
  }, null, 'start')
  .set(stepBtns, {pointerEvents: 'none'}, 'start')
  .set(links, {pointerEvents: 'auto'}, 'start');

  currentIndex = 0;
  start = true;
}

function handleLink(e) {
  e.preventDefault();
  const clicked = e.currentTarget;
  let index = parseInt(clicked.dataset.index);

  if (clicked === prevBtn && index === -1) {
    currentIndex = 0;
    backToStart();
    return;
  }

  if (index === numSections - 1) {
    nextBtn.disabled = true;
  } else {
    nextBtn.disabled = false;
  }

  const bubble = clicked.querySelector('.js-bubble-circle');
  if (bubble) {
    gsap.killTweensOf(bubble);
    gsap.set(bubble, {clearProps: 'all'});
  }

  const tl = gsap.timeline();
  tl.add('start');
  slideTl.pause();

  if (start) {
    tl
    .to(svgMain, {
      scale: scaleFactor,
      x: posVals[index].x,
      y: posVals[index].y,
      duration: 0.5,
    }, 'start')
  } else {
    tl.to(svgMain, {
      scale: scaleFactor,
      x: posVals[index].x,
      y: posVals[index].y,
      duration: 0.5,
    }, 'start')
    .add(circleItems[currentIndex].outTl.restart(), 'start')
    .add(blockItems[currentIndex].outTl.restart(), 'start')
    .add(imageItems[currentIndex].outTl.restart(), 'start')
  }

  tl
  .set(stepBtns, {pointerEvents: 'auto'}, 'start')
  .set(links, {pointerEvents: 'none'}, 'start')
  .add(circleItems[index].inTl.restart(), 'start')
  .add(blockItems[index].inTl.restart(), 'start')
  .add(imageItems[index].inTl.restart(), 'start')
  .to(starHeadings, {
    opacity: 0,
    duration: 0.25,
    pointerEvents: 'none',
  }, 'start')
  .to(homePrompts, {
    opacity: 0,
    y: 10,
    stagger: 0.1,
    duration: 0.25,
    pointerEvents: 'none',
    overwrite: true,
  }, 'start')
  .to(homePromptsParent, {
    zIndex: -1,
    duration: 0.25,
    overwrite: true,
  }, 'start')
  .to(homeHeading, {
    opacity: 0,
    y: -10,
    duration: 0.25,
  }, 'start')
  .to([prevBtn, nextBtn], {
    opacity: 1,
    duration: 0.25,
    stagger: 0.1,
  }, 'start')
  .to(header, {
    opacity: 0,
    y: -10,
    duration: 0.25,
  }, 'start')
  .to(window, {
    scrollTo: 0,
    duration: 0.25,
  }, 'start')
  .call(() => {
    body.classList.add('desktop-overview-is-active')
  }, null, 'start');

  prevBtn.dataset.index = index - 1;
  nextBtn.dataset.index = index + 1;

  currentIndex = index;
  start = false;
}

function handleEnter(e) {
  const index = e.currentTarget.dataset.index;
  const el = document.querySelector(`.js-svg-link[data-index="${index}"]`);
  const circle = el.querySelector('.js-star-circle');
  const pos = el.querySelector('.js-bubble-pos');
  const bubble = el.querySelector('.js-bubble-circle');
  const data = pos.getBBox();
  const tl = gsap.timeline();

  const circleAnims = gsap.getTweensOf(circle);
  const bubbleAnims = gsap.getTweensOf(bubble);
  if (bubbleAnims.length || circleAnims.length) {
    if (bubbleAnims.length) {
      gsap.killTweensOf(bubble);
      gsap.set(bubble, {clearProps: 'all'});
    }
    return;
  }

  tl.fromTo(bubble, {
    attr: {
      cx: data.width/2,
      cy: data.height/2,
      r: 0,
    },
    strokeWidth: 0,
    opacity: 1,
  }, {
    attr: {
      r: 50,
    },
    strokeWidth: 3,
    opacity: 0,
    duration: 0.6,
  }).then(() => {
    gsap.set(bubble, {
      attr: {
        r: 0,
      },
      strokeWidth: 0,
    })
  })
}

export default function() {
  const initialHeight = window.innerHeight;
  const svg = document.querySelector('#star');

  if (!svg) {
    return;
  }

  svgMain = document.querySelector('#star-main');
  svgNodes = svgMain.querySelectorAll("*");
  homeHeading = document.querySelector('#home-heading');
  homeHeadingParent = document.querySelector('#home-heading-parent');
  starHeadingParents = Array.from(document.querySelectorAll('.js-star-heading-parent'));
  starHeadings = Array.from(document.querySelectorAll('.js-star-heading'));
  lines = Array.from(document.querySelectorAll('.js-star-line'));
  coral = Array.from(document.querySelectorAll('.js-star-coral'));
  hexagon = document.querySelector('#star-hexagon');
  header = document.querySelector('#header');
  footer = document.querySelector('#footer');
  homePromptsParent = document.querySelector('#home-prompts');
  homePrompts = Array.from(document.querySelectorAll('.js-home-prompt'));
  storyBtns = Array.from(document.querySelectorAll('.js-story-btn'));
  closeBtn = document.querySelector('#hide-video');
  links = Array.from(document.querySelectorAll('.js-star-link'));
  svgLinks = Array.from(document.querySelectorAll('.js-svg-link'));
  resetLinks = Array.from(document.querySelectorAll('.js-reset-link'));
  beginBtn = document.querySelector('#home-begin');
  prevBtn = document.querySelector('#prev-btn');
  nextBtn = document.querySelector('#next-btn');
  stepBtns = document.querySelector('#home-step-btns');

  initVideo();
  initSlideShow();

  svgNodes.forEach((item) => {
    item.setAttribute('vector-effect', 'non-scaling-stroke');
  });

  const circles = Array.from(document.querySelectorAll('.js-star-circle'));
  circleItems = circles.map((circle, index) => {
    const icon = circle.querySelector('.js-circle-icon');
    const number = circle.querySelector('.js-circle-number');

    const inTl = gsap.timeline({paused: true});
    inTl
    .add('start')
    .fromTo(circle, {
      scale: 1,
    }, {
      duration: 0.5,
      scale: 3,
      transformOrigin: function() {
        return `${circle.dataset.initOrigin}`;
      }
    }, 'start')
    .from(icon, {
      duration: 0.5,
      opacity: 0,
    }, 'start')
    .to(number, {
      duration: 0.5,
      opacity: 0,
    }, 'start');

    const outTl = gsap.timeline({paused: true});
    outTl
    .add('start')
    .to(circle, {
      duration: 0.5,
      scale: 1,
    }, 'start')
    .to(icon, {
      duration: 0.5,
      opacity: 0,
    }, 'start')
    .to(number, {
      duration: 0.5,
      opacity: 1,
    }, 'start');

    return {
      inTl: inTl,
      outTl: outTl
    }
  });

  const overviewBlocks = Array.from(document.querySelectorAll('.js-overview'));
  blockItems = overviewBlocks.map((block, index) => {
    const content = block.querySelector('.js-overview-content');
    const els = Array.from(content.children);

    const inTl = gsap.timeline({paused: true});
    inTl
    .add('start')
    .from(block, {
      zIndex: -1,
    }, 'start')
    .from(els, {
      opacity: 0,
      y: 10,
      stagger: 0.1,
      duration: 0.5,
      ease: 'none',
    }, 'start');

    const outTl = gsap.timeline({paused: true});
    outTl
    .add('start')
    .to(block, {
      zIndex: -1,
      duration: 0.25,
    }, 'start')
    .to(els, {
      opacity: 0,
      y: -10,
      stagger: 0.1,
      duration: 0.25,
      ease: 'none',
    }, 'start');

    return {
      inTl: inTl,
      outTl: outTl
    }
  });

  const images = Array.from(document.querySelectorAll('.js-overview-image'));
  imageItems = images.map((image, index) => {
    const inTl = gsap.timeline({paused: true});
    inTl.from(image, {
      opacity: 0,
      duration: 0.25,
      ease: 'none',
    });

    const outTl = gsap.timeline({paused: true});
    outTl.to(image, {
      opacity: 0,
      duration: 0.25,
      ease: 'none'
    });

    return {
      inTl: inTl,
      outTl: outTl,
    }
  });

  const introTl = gsap.timeline();
  introTl
  .add('start')
  .from(homeHeadingParent, {
    opacity: 0,
    y: -10,
    duration: 0.25,
  }, 'start')
  .from(hexagon, {
    opacity: 0,
    rotate: -30,
    scale: 0,
    duration: 0.25,
    transformOrigin: 'center center',
  }, 'start')
  .from(lines, {
    opacity: 0,
    scale: 0,
    duration: 0.5,
    ease: "expo.inOut",
    transformOrigin: function(index, line) {
      return `${line.dataset.initOrigin}`;
    },
    stagger: 0.2
  })
  .add('circles')
  .from(circles, {
    opacity: 0,
    scale: 0,
    stagger: 0.1,
    duration: 0.25,
    transformOrigin: function(index, circle) {
      return `${circle.dataset.initOrigin}`;
    }
  }, 'circles')
  .fromTo(starHeadingParents, {
    opacity: 0,
    y: 10,
  }, {
    opacity: 1,
    y: 0,
    stagger: 0.1,
    duration: 0.5,
  }, 'circles')
  .from(coral, {
    opacity: 0,
    scale: 0,
    duration: 1,
    ease: "expo.inOut",
    transformOrigin: 'center center',
  }, 'circles')
  .fromTo(homePrompts, {
    opacity: 0,
    y: 10,
  }, {
    opacity: 1,
    y: 0,
    stagger: 0.1,
    duration: 0.25,
  });

  links.forEach((link, i) => {
    link.addEventListener('click', handleLink);
    link.addEventListener('mouseenter', handleEnter);
  });

  svgLinks.forEach((link, i) => {
    link.addEventListener('click', handleLink);
    link.addEventListener('mouseenter', handleEnter);
  });

  resetLinks.forEach((link, i) => {
    link.addEventListener('click', backToStart);
  });

  prevBtn.addEventListener('click', handleLink);
  nextBtn.addEventListener('click', handleLink);
  beginBtn.addEventListener('click', handleLink);
}

let slider;
let slideWidth;
let range;
let firstImage;
let picture;
let overlay;
let initVal;

function handleResize() {
  // Setup a timer
  let timeout;

  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}

    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      updateSlider();
  	});
  }, false);
}

function updateSlider() {
  picture.style.width = `${slider.offsetWidth}px`;
}

export default function() {
  slider = document.querySelector('#slider');

  if (!slider) {
    return;
  }

  slideWidth = slider.offsetWidth;
  range = slider.querySelector('#slider-range');
  firstImage = slider.querySelector('#slider-image-1');
  picture = firstImage.querySelector('picture');
  overlay = slider.querySelector('#slider-overlay');
  initVal = range.value;

  picture.style.width = `${slideWidth}px`;
  firstImage.style.width = `${initVal}%`;
  overlay.style.left = `${initVal}%`;

  range.addEventListener('input', function() {
    firstImage.style.width = `${this.value}%`;
    overlay.style.left = `${this.value}%`;
  })

  handleResize();
}

import gsap from 'gsap';
import Glide from '@glidejs/glide';
import Player from '@vimeo/player';

let currentVideo;
let slides;
let players = [];

function initVideo(el) {
  const video = el.querySelector('.js-carousel-video');

  if (!video) {
    return
  }

  const videoContainer = video.querySelector('.js-carousel-video-container');
  const url = videoContainer.dataset.url;

  if (!url) {
    return;
  }

  const player = new Player(videoContainer, {
    url: url,
    byline: false,
    responsive: true,
    title: false,
    muted: true,
  });

  const playerOb = {
    player: player,
    el: el,
  }

  players.push(playerOb);
}

export default function() {
  // Assumes one carousel per page
  const carouselEl = document.querySelector('#carousel')
  if (carouselEl) {
    const carousel = new Glide(carouselEl, {
      type: 'carousel',
      gap: 0,
      dragThreshold: false
    });

    carousel.on('run.before', function(e) {
      if (currentVideo) {
        currentVideo.pause();
      }

      const caption = document.querySelector(`[data-caption-index="${carousel.index}"]`);
      gsap.to(caption, {
        opacity: 0,
        y: 10,
        duration: 0.1,
        ease: 'none'
      });
    });

    carousel.on('run.after', function(e) {
      const slide = document.querySelector('.glide__slide--active');
      const caption = document.querySelector(`[data-caption-index="${carousel.index}"]`);
      gsap.fromTo(caption, {
        opacity: 0,
        y: 10,
      }, {
        opacity: 1,
        y: 0,
        duration: 0.1
      });

      if (slide.dataset.slideType === 'video') {
        const playerOb = players.find(item => item.el === slide);
        playerOb.player.play().catch(function(err) {
          console.log(err);
        });
      }
    });

    carousel.on('mount.after', function() {
      slides = Array.from(carouselEl.querySelectorAll('.js-slide'));

      // Check for video slides
      const videoEls = slides.filter(item => item.dataset.slideType === 'video');
      if (videoEls) {
        videoEls.forEach(item => initVideo(item));
      }
    });

    carousel.mount();
  }
}

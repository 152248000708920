import gsap from 'gsap';
import Player from '@vimeo/player';

let currentVideo;
let players = [];
const body = document.body;

function closeModal(params) {
  const { modal, player } = params;

  player.pause();
  player.setCurrentTime(0);

  const tl = gsap.timeline();
  tl
  .to(modal, {
    opacity: 0,
    duration: 0.1,
  })
  .set(modal, {left: 'calc(-100% - 100vw)'})
  .set(body, {overflow: 'auto'})
}

function showVideo(params) {
  const { modal, video, player } = params;

  const tl = gsap.timeline();
  tl
  .set(modal, {left: 0})
  .set(body, {overflow: 'hidden'})
  .fromTo(modal, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.1,
  })
  .from(video, {
    opacity: 0,
    y: 10,
    duration: 0.1,
    onComplete: () => {
      player.setCurrentTime(0);
      player.play();
    }
  });
}

function showModal(e) {
  e.preventDefault();
  const clicked = e.currentTarget;
  const url = clicked.getAttribute('href');

  if (!url) {
    return;
  }

  const body = document.body;
  const modal = document.querySelector(`[data-url="${url}"]`);
  const video = modal.querySelector('.js-resource-video');
  const container = modal.querySelector('.js-resource-video-container');
  const closeBtn = modal.querySelector('.js-resource-video-close');

  if (!modal) {
    return;
  }

  let playerObj = players.find(item => item.el === modal);

  if (!playerObj) {
    const modal = document.querySelector(`[data-url="${url}"]`);
    const video = modal.querySelector('.js-resource-video');
    const container = modal.querySelector('.js-resource-video-container');
    const closeBtn = modal.querySelector('.js-resource-video-close');
    const player = new Player(container, {
      url: url,
      byline: false,
      responsive: true,
      title: false,
    });

    playerObj = {
      el: modal,
      modal: modal,
      player: player,
      video: video,
    }

    players.push(playerObj);
    closeBtn.addEventListener('click', function() {
      closeModal(playerObj);
    });
  }

  showVideo(playerObj);
}

export default function() {
  const links = Array.from(document.querySelectorAll('.js-resource-video-link'));

  if (!links.length) {
    return;
  }

  links.forEach(link => link.addEventListener('click', showModal));
}
